<template>
  <v-container
    fluid
    tag="section"
  >

  <v-dialog  v-model="alert_infor"  width="60%">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Logs
      </v-card-title>
      <v-card-text>
  
          <v-data-table
              :headers="headers_log"
              :items="lista_log"
              :items-per-page="5" :footer-props="{
                'items-per-page-options': [5],
                'items-per-page-text':'Registro por página'
              }"
              class="elevation-0">
  
                        <template v-slot:item="row">
                          <tr>
                            <td style="width: 12%;font-size:14px;">{{row.item.user}} </td>
                            <td style="width: 12%;font-size:14px;">{{row.item.usuario}} </td>
                            <td style="width: 10%;font-size:14px;">{{row.item.data_insert}} </td>
                            <td style="width: 15%;font-size:14px;">{{row.item.acao}} </td>
                            <td style="width: 35%;font-size:14px;">{{row.item.valor}} </td>
                          </tr>
                      </template>
  
  
            </v-data-table>
  
      </v-card-text>
  
      <v-divider></v-divider>
  
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="alert_infor = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{alert_msg_descricao}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



  <v-dialog  v-model="dlg_novo"  max-width="600px">
     <v-card>

    <v-toolbar
                                      text
                                        color="primary"
                                        dark
                                      > <h3> Novo Usuário</h3>
                          </v-toolbar>
     


        <v-card-text>
          <v-form ref="form" style="margin-top:20px"     lazy-validation> 

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="signupRequest.nome" style="font-size:20px;" 
                  outlined :rules="nameRules"
                  clearable 
                  label="Nome Completo"
                  required
                ></v-text-field>
              </v-col>

                <v-col cols="12">
                  <v-text-field v-model="signupRequest.telefone" style="font-size:20px; font-color:red;" 
                    outlined :rules="nameRules"
                    clearable 
                    label="Telefone"  v-mask="'(##) #####-####'"
                    required
                  ></v-text-field>
                </v-col>

      

              <v-col cols="12">
                <v-text-field v-model="signupRequest.username" style="font-size:20px; font-color:red;" :counter="50" 
                    outlined :rules="emailRules"
                  clearable 
                  label="E-mail" required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="signupRequest.password" style="font-size:20px; font-color:red;" :counter="12" 
                     outlined :rules="passRules"
                  clearable 
                  type="password"
                  label="Senha"
                  required
                ></v-text-field>
              </v-col>

              </v-row>
          </v-container>
          <small>*campos requeridos</small>
           </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text 
            @click="dlg_novo = false"
          >
            Sair
          </v-btn>
          <v-btn 
            color="success"
            @click="cadastrar"
          >
            OK
            <v-icon
        right
        dark
      >
        mdi-check
      </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>



  <v-dialog  v-model="dlg_edit"  max-width="600px">
    <v-card>

   <v-toolbar
                                     text
                                       color="primary"
                                       dark
                                     > <h3> Editar Usuário</h3>
                         </v-toolbar>
    


       <v-card-text>
         <v-form ref="formedit" style="margin-top:20px"     lazy-validation> 

         <v-container>
           <v-row>
             <v-col cols="12">
               <v-text-field v-model="signupRequest.nome" style="font-size:20px;" 
                 outlined :rules="nameRules"
                 clearable 
                 label="Nome Completo"
                 required
               ></v-text-field>
             </v-col>

               <v-col cols="12">
                 <v-text-field v-model="signupRequest.telefone" style="font-size:20px; font-color:red;" 
                   outlined :rules="nameRules"
                   clearable 
                   label="Telefone"  v-mask="'(##) #####-####'"
                   required
                 ></v-text-field>
               </v-col>


             <v-col cols="12">
               <v-text-field v-model="signupRequest.username" style="font-size:20px; font-color:red;" :counter="50" 
                   outlined :rules="emailRules"
                 clearable 
                 label="E-mail" required
               ></v-text-field>
             </v-col>

             </v-row>
         </v-container>
         <small>*campos requeridos</small>
          </v-form>

       </v-card-text>

       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn
           color="success"
           text 
           @click="dlg_edit = false"
         >
           Sair
         </v-btn>
         <v-btn 
           color="success"
           @click="editar"
         >
           OK
           <v-icon
       right
       dark
     >
       mdi-check
     </v-icon>
         </v-btn>
       </v-card-actions>
     </v-card>

   </v-dialog>

    <v-dialog  v-model="dlg_permissao"  width="800">

     <v-card>
     

    <v-toolbar
                                      text
                                        color="primary"
                                        dark
                                      > <h3> Lista Permissões</h3>
                          </v-toolbar>
     


        <v-card-text>
          

          <v-container>
            <v-row>

          <v-data-table :single-select="false"  :loading="loading_roles" :items-per-page="100"
           hide-default-footer :headers="headers_roles" v-model="user.newrole"  :items="roles"  show-select item-key="name">
          </v-data-table>   
          </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text 
            @click="dlg_permissao = false"
          >
            Sair
          </v-btn>
          <v-btn 
            color="success"
            @click="cadastrar_permissao()"
          >
            OK
            <v-icon
        right
        dark
      >
        mdi-check
      </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    
<base-material-card
      icon="mdi-account"
      title="Usuários Cadastrados"
      class="px-5 py-3"
    >
      <v-simple-table :loading="loading">
            <thead>
              <tr>
                <th style="width: 30%;font-size:14px;font-weight: bold;">
                  Nome
                </th>
                <th  style="width: 20%;font-size:14px;font-weight: bold;">
                  Email
                </th>

                <th  style="width: 6%;font-size:14px;font-weight: bold;">
                  Acesso
                </th>

                <th  style="width: 6%;font-size:14px;font-weight: bold;">
                  Situação
                </th>

                <th style="width: 40%;font-size:14px;font-weight: bold;">
                  Ações
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in usuarios"
                :key="item.id"
              >
                <td>{{ item.nome }}  <br/>
                  <v-icon>
                    mdi-phone
                  </v-icon>
                {{ item.telefone }}</td>
                
                <td>{{ item.email }}</td>
                <td>{{ item.acesso_now }}</td>

                <td>

                    <v-chip
                          class="ma-2"
                          :color="getBadge(item.ativo)"
                          text-color="white"
                        >
                              {{getStatus(item.ativo)}}                    
                              
                    </v-chip>

              
              </td>


                <td >
                  <v-tooltip bottom >    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2" fab  dark :disabled="item.ativo == false"   small :loading="btn_status" v-bind="attrs" v-on="on" @click="editrPerfil(item)"
                      color="blue">
                        <v-icon dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn> 
                    </template>
                    <span>Editar</span>
                  </v-tooltip>  


                    <v-tooltip bottom v-if="item.ativo == false">    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2"   fab  dark   small :loading="btn_status" v-bind="attrs" v-on="on" @click="mudarStatus(index, item)" 
                      color="warning">
                        <v-icon dark>
                          mdi-check
                        </v-icon>
                      </v-btn> 
                    </template>
                    <span>Habilitar</span>
                  </v-tooltip>  



                   <v-tooltip bottom v-if="item.ativo == true">    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2"   fab  dark   small :loading="btn_status" v-bind="attrs" v-on="on" @click="mudarStatus(index, item)" 
                      color="red">
                        <v-icon dark>
                          mdi-close
                        </v-icon>
                      </v-btn> 
                    </template>
                    <span>Desabilitar</span>
                  </v-tooltip>  

                  <v-tooltip bottom >    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2" fab  dark  :disabled="item.ativo == false" small :loading="btn_status" v-bind="attrs" v-on="on" @click="ajutarPerfil(item)"
                      color="danger">
                        <v-icon dark>
                          mdi-account-box
                        </v-icon>
                      </v-btn> 
                    </template>
                    <span>Perfil</span>
                  </v-tooltip>  
                  

                  <v-tooltip bottom >    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2" fab  dark :disabled="item.ativo == false"   small :loading="btn_status" v-bind="attrs" v-on="on" @click="enviarSMS(item.id)"
                      color="blue">
                        <v-icon dark>
                          mdi-send
                        </v-icon>
                      </v-btn> 
                    </template>
                    <span>Enviar SMS de Ativação</span>
                  </v-tooltip>  
                  

                  

                </td>
              </tr>
            </tbody>
            <br/>
          </v-simple-table>   

          <br/>
          <v-btn color="success"  @click="novo_usuario()" > NOVO USUÁRIO
          
           <v-icon
            right
            dark
          >
            mdi-account-multiple-plus
          </v-icon>
    
          </v-btn>

          <v-btn color="orange"  @click="getInfor()" > LOG
          
            <v-icon
             right
             dark
           >
           mdi-application-braces-outline
           </v-icon>
     
           </v-btn>

    </base-material-card>

  

  

    
    
  </v-container>
</template>
<script>
  import Usuario from '../../services/usuario'
  import Roles from '../../services/roles'

  export default {


     created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
      },

    data: () => ({

    nameRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=4) || 'Usuário deve ter pelo menos 6 caracteres',
        v => (v && v.length <= 30) || 'Usuário deve ter pelo até 30 caracteres',

      ],

       emailRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=5) || 'Email deve ter pelo menos 5 caracteres',
        v => (v && v.length <= 50) || 'Email deve ter pelo até 50 caracteres',

      ],


       passRules: [
        v => !!v || 'Campo Obrigatório',
        v => (v && v.length >=6) || 'Senha deve ter pelo menos 6 caracteres',
        v => (v && v.length <= 12) || 'Senha deve ter pelo até 12 caracteres',

      ],


      headers_roles: [
        { text: 'Permissão', value: 'name' },
        { text: 'Menu', value: 'menu' }, // Coluna modificada
        { text: 'Descrição', value: 'descricao' }, // Coluna modificada

      ],

      usuarios: [],
      select_roles: [],
      dlg_novo: false,
      dlg_edit: false,
      btn_status: false,
      dlg_permissao: false,
      loading_roles: false,
      alert_msg: false,
      alert_msg_descricao: '',
      lista_log: [],
      alert_infor: false,
      headers_log: [
        { text: 'Administrador', value: 'user' },
          { text: 'Usuário', value: 'usuario' },
          { text: 'Data', value: 'data_insert' },
          { text: 'Ação', value: 'acao' },
          { text: 'Valor', value: 'valor' },


        ],
      user:{
        id:'',
        newrole:[],
      },
      roles:[],
      authority:[],
      loading: false,
      selected: [],
      signupRequest:{
          id: '',
          username: '',
          nome: '',
          telefone:'',
          password: '',
      },
      dlg_novo: false,
      msg_dialog: '',
      dialog: false,
      checkboxNames: ['01', '02', '03', '04'],
      search: '',
        headers: [
          {
            text: 'Nome',
            filterable: false,
            value: 'name',
          },
          { text: 'Email' },
          { text: 'Situação' },

          { text: 'Ações', value: 'fat' },
        ],



    }),


    mounted () {
      this.listar();
      this.listarRoles();
      //this.clear();

    },


    methods: {
      listar () {
       this.loading = true

        Usuario.lista().then(response => {
            this.usuarios = response.data
        }).catch(e => {
           

        }).finally(() => {
            this.loading = false

        })
      },

       userPermissao(id){

        Roles.user(id).then(response => {
          this.user.newrole = response.data

        }).catch(e => {
        }).finally(() => {
          this.dlg_permissao = true;
        })
       },

      

       listarRoles () {
         //this.loading_roles = true;
        Roles.lista().then(response => {
            this.roles = response.data
        }).catch(e => {
            this.loading_roles = false;

        }).finally(() => {
          this.loading_roles = false;
        })
      },

      clear () {       
        this.$refs.form.reset();
      },

      novo_usuario(){
        this.dlg_novo = true;
      },

      

      cadastrar_permissao(){
           Usuario.updatePermissao(this.user.id, this.user.newrole).then(response => {
                      this.user = {};
                      if(response.status === 200){
                        this.alert_msg = true;
                        this.alert_msg_descricao = "Atualizado com sucesso";
                        this.dlg_permissao = false;
                        } 


                      }).catch(e => {
                        if(e.response.status === 403){
                            alert("Usuário não tem permissão")
                        }
                        
                      }).finally(() => {

                      })
      },

     
      cadastrar() {
          if(this.$refs.form.validate()){
              Usuario.cadastro(this.signupRequest).then(response => {
                      this.alert_msg = true
                      if(response.status === 200){
                          this.usuarios.push(response.data)
                          this.alert_msg_descricao = 'Cadastrado com Sucesso'
                      }
                                             
                      this.dlg_novo = false;

                      }).catch(e => {
                          this.dlg_novo = false;
                          this.alert_msg = true
                          this.alert_msg_descricao = 'Usuário ou email já existem'
                         

                        if(e.response.status === 403){
                            alert("Usuário não tem permissão")
                        }

                        
                      }).finally(() => {
                         this.dlg_novo = false;
                         this.signupRequest.username = "",
                         this.signupRequest.telefone = "",
                         this.signupRequest.password = ""
                         this.signupRequest.nome = "",
                         this.signupRequest.id = ""

                      })

          }  
      },

      editar() {
          if(this.$refs.formedit.validate()){
              Usuario.editar(this.signupRequest).then(response => {
                      this.alert_msg = true
                      if(response.status === 200){
                          this.usuarios.push(response.data)
                          this.alert_msg_descricao = response.data.mensagem
                      }
                                             
                      this.dlg_edit = false;

                      }).catch(e => {
                          this.dlg_edit = false;
                          this.alert_msg = true
                          this.alert_msg_descricao = 'Usuário ou email já existem'
                         

                        if(e.response.status === 403){
                            alert("Usuário não tem permissão")
                        }

                        
                      }).finally(() => {
                         this.dlg_edit = false;
                         this.signupRequest.username = "",
                         this.signupRequest.telefone = "",
                         this.signupRequest.password = ""
                         this.signupRequest.nome = "",
                         this.signupRequest.id = ""
                               this.listar();

                      })

          }  
      },
      mudarStatus(pos, item){
            this.btn_status = true;
            Usuario.updateStatusUsuario(item.id).then(response => {
                        this.usuarios.indexOf(item)
          item.ativo = response.data;
          Object.assign(this.usuarios[pos], item)
            //this.usuarios.push(item)

        }).catch(e => {
                        this.btn_status = false;

        }).finally(() => {
                      this.btn_status = false;

        })
      },

       ajutarPerfil(item){
          
          this.user.newrole = [];
          this.userPermissao(item.id);
          this.user = item; 
         
      },


      editrPerfil(item){
        this.signupRequest.nome = item.nome,
        this.signupRequest.id = item.id,
        this.signupRequest.username = item.email,
        this.signupRequest.telefone = item.telefone,

        this.dlg_edit = true;
      },

      enviarSMS(id){
        this.btn_status = true;

           Usuario.smsAtivacao(id).then(response => {
                      this.user = {};
                      if(response.status === 200){
                        this.alert_msg = true;
                        this.alert_msg_descricao = response.data.mensagem;
                        this.dlg_permissao = false;
                        } else{
                          this.alert_msg = true;
                          this.alert_msg_descricao = response.data.mensagem;
                        }


                      }).catch(e => {
                        if(e.response.status === 403){
                          this.alert_msg = true;
                          this.alert_msg_descricao = "Usuário não tem permissão";
                        }
                        
                      }).finally(() => {
                        this.btn_status = false;

                      })
      },

      getInfor(){
          this.alert_infor = true;

          Usuario.logs().then(response => {
                if(response.status = 200){
                  this.lista_log = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });

      },

      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

      getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },

    

    }
  }

</script>
